import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Button, BUTTON_TYPES, BUTTON_VARIANT, classNames, Dropdown, handleErrors, IconDelete, MESSAGE_BAR_TYPES, Modal, MODAL_SIZES, Pagination, Panel, RenderWhen, showMessageBar, SingleSelect, Table, TextAreaInput, TextInput } from "@klenty/klenty-ui";
import { CellProps, Column } from "react-table";
import "./aiPromptDashboard.css";
import { ReactComponent as ChevronDown } from "../../../assets/img/chevronDown.svg";
import { ReactComponent as Close } from "../../../assets/img/close.svg";
import { ReactComponent as Edit } from "../../../assets/img/edit.svg";
import { aiPromptDashboardCategories, allowedEmails } from "../../../utils/constants";
import CreatePlanModal from "./CreatePlanModal";
import { BlueprintsConfig, CurrPlanDetailsType, PlanMetaApiResponse, PlanMetaCategoryType, PlanMetaItem, transformedObject } from "./types";

interface IDescriptionModalProps {
  showDescriptionModal: boolean;
  setShowDescriptionModal: (value: boolean) => void
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void
  sortAndUpdateAIPromptDashboardData: (value: Record<string, string | boolean>[]) => void
  aiPromptDashboardData: Record<string, string | boolean>[],
  planMeta: Record<PlanMetaCategoryType, PlanMetaItem[]> | undefined,
  doRefresh: VoidFunction,
  editedRowData: any,
  isEdit: boolean,
  setIsEdit: (bool:boolean) => void
}

function AIPromptDashboard({ emailId }: { emailId: string }) {
  const [selectedCategories, setSelectedCategories] = useState<{ label: string, value: string }[]>([]);
  const [aiPromptDashboardData, setAIPromptDashboardData] = useState<Record<string, string | boolean>[]>([]);
  const [editedRowData, setEditedRowData] = useState<Record<string, string | boolean> | null>(null);
  const [isEdit, setIsEdit] = useState(false)
  const [state, setState] = useState({
    isLoading: false,
    isError: false,
    isDisabled: false,
    categoryDropdownOpen: false,
    showDescriptionPanel: false,
    showDescriptionModal: false,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [canDeletePrompt , setCanDeletePrompt] =  useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const [planMeta, setPlanMeta] = 
  useState<Record<PlanMetaCategoryType, PlanMetaItem[]>>();

  const [trigger, setTrigger] = useState(0);

  function doRefresh(){
    setTrigger(prev => prev + 1)
  } 

  const setSpecificState = (key: keyof typeof state, value: boolean) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const categoryMap = useMemo(() => {
    return new Map(aiPromptDashboardCategories.map((cat) => [cat.value, cat.label]));
  }, []);

  const sortAndUpdateAIPromptDashboardData = (data: Record<string, string | boolean>[]) => {
    setAIPromptDashboardData([...data, { name: "", category: '', hint: "", description: "", isAddRow: true } as Record<string, string | boolean>].sort((a: Record<string, string | boolean>, b: Record<string, string | boolean>) => {
      if (a.isAddRow) return 1;
      if (b.isAddRow) return -1;
      return (a.category as string).localeCompare(b.category as string);
    }));
  }

  useEffect(() => {
    getAndSetMetaForCreataPlanModal()
    setSpecificState('isLoading', true);
    axios.get(`/api/agents/agentPromptMeta`, {
      params: {
        page: currentPage,
        limit: 30,
        categories: selectedCategories.length ? selectedCategories.map(cat => cat.value).join(',') : null
      }
    }).then((response) => {
      const { data, count } = response.data;
      if (response.status === 200 && data && data.length) {
        sortAndUpdateAIPromptDashboardData(data);
        setTotalPages(Math.ceil((count || 0) / 30));
      }
      else {
        setAIPromptDashboardData([]);
      }
    })
      .catch((error) => {
        setSpecificState('isError', true);
        showMessageBar({
          type: MESSAGE_BAR_TYPES.DANGER,
          heading: 'Something went wrong',
        });
      })
      .finally(() => setSpecificState('isLoading', false));      
  }, [currentPage, selectedCategories, trigger]);


  async function getAndSetMetaForCreataPlanModal() {
    const res = await axios
      .get<PlanMetaApiResponse>("/api/agents/planMeta")
      .then((res) => res.data)
      .catch((err) => handleErrors(err));
      if (res?.length) {
        const transformedMeta = res.reduce<Record<PlanMetaCategoryType, PlanMetaItem[]>>(
          (acc, curr) => {
            acc[curr._id as PlanMetaCategoryType] = curr.items;
            return acc;
          },
          {} as Record<PlanMetaCategoryType, PlanMetaItem[]> 
        );
        setPlanMeta(transformedMeta);
      }
    return 
  }

  const generateColors = (categories: string[]) => {
    const colors = [
      "#FFAD29", "#2EABFF", "#4caf50", "#9c27b0", "#f44336", "#3f51b5", "#009688", "#795548",
    ];
    const colorMap: Record<string, string> = {};
    categories.forEach((category, index) => {
      colorMap[category] = colors[index % colors.length];
    });
    return colorMap;
  };

  const dotColors = generateColors(Array.from(categoryMap.values()));

  const columns: Column<Record<string, string | boolean>>[] = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }: CellProps<Record<string, string | boolean>>) => {
        if (row.original.isAddRow) {
          return (
            <div style={{ backgroundColor: "#F5F5F7", }}></div>
          );
        }

        const color = dotColors[categoryMap.get(row.original.category as string) as string] || "#000";

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundColor: color,
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            ></div>
            <span className="clamped-text">{row.original.name}</span>
          </div>
        );
      },
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: ({ row }: CellProps<Record<string, string | boolean>>) => {
        if (row.original.isAddRow) return (<div style={{ backgroundColor: "#F5F5F7", }}></div>);
        return <span className="clamped-text">{row.original.category}</span>;
      },
    },
    {
      Header: "Description",
      accessor: "hint",
      Cell: ({ row }: CellProps<Record<string, string | boolean>>) => {
        if (row.original.isAddRow) return (<div style={{ backgroundColor: "#F5F5F7", }}></div>);
        return <span className="clamped-text">{row.original.hint || "No description available"}</span>;
      },
    },
    {
      Header: "Prompt",
      accessor: "description",
      Cell: ({ row }: CellProps<Record<string, string | boolean>>) => {
        if (row.original.isAddRow) {
          return (
            <div
              style={{
                backgroundColor: "#F5F5F7",
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
                padding: "16px",
                fontSize: "12px",
                lineHeight: "16px",
                color: '#080C2BCC',
                fontWeight: '500'
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                setSpecificState('showDescriptionModal', !state.showDescriptionModal);
              }}
            >
              <span style={{
                  color: "#080C2B99",
                  marginRight: "2px",
              }}>+</span>Add Row
            </div>);
        }
        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "flex-start",
              height: "100%",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => {
              const icon = e.currentTarget.querySelectorAll(".edit-icon, .action-icon");
              icon.forEach((icon) => (icon as HTMLElement).style.visibility = "visible");
            }}
            onMouseLeave={(e) => {
              const icon = e.currentTarget.querySelectorAll(".edit-icon, .action-icon");
              icon.forEach((icon) => (icon as HTMLElement).style.visibility = "hidden");
            }}
          >
            <span className="clamped-text">{row.original.description}</span>
            <div style={{ display: "flex"}}>
              <div
                className="edit-icon"
                style={{
                  position: "absolute",
                  right: "70px",
                  visibility: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "40px",
                  height: "40px",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setEditedRowData(row.original);
                setIsEdit(true)
                setSpecificState('showDescriptionModal', !state.showDescriptionModal)
                }}
              >
                <Edit width={16} height={16} />
              </div>
              <div
                className="action-icon"
                style={{
                  position: "absolute",
                  right: "10px",
                  visibility: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                  height: "40px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                }}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setSelectedRowId(row.original._id as string);
                  setCanDeletePrompt(true);
                }}
              >
                <IconDelete />
              </div>

            </div>
          </div>
        );
      },
    },
  ];

  const handleSelect = (selected: { label: string, value: string }[]) => {
    setSelectedCategories(selected);
    setCurrentPage(1);
  };

  const handleRemove = (categoryToRemove: { label: string, value: string }) => {
    setSelectedCategories((prev) =>
      prev.filter((category) => category.value.toLowerCase() !== categoryToRemove.value.toLowerCase())
    );
    setCurrentPage(1);
    setSpecificState('categoryDropdownOpen', false);
  };

  const handleDelete = async (_id: string) => {
    if (!_id) {
      showMessageBar({
        type: MESSAGE_BAR_TYPES.WARNING,
        heading: "Invalid ID. Cannot delete.",
      });
      return;
    }
    try {
      await axios.delete(`/api/agents/agentPromptMeta/${_id}`);
      setAIPromptDashboardData((prevData) => {
        const updatedData = prevData.filter((item) => item._id !== _id);
        return updatedData;
      });
      showMessageBar({
        type: MESSAGE_BAR_TYPES.SUCCESS,
        heading: "Prompt deleted successfully!",
      });
    } catch (error) {
      showMessageBar({
        type: MESSAGE_BAR_TYPES.DANGER,
        heading: "Failed to delete prompt",
      });
    }
    finally{
      setCanDeletePrompt(false);
    }
  };
  
  return (
    allowedEmails.includes(emailId) ? (state.isLoading ? <div className="Loading-page">Loading...</div> :
      state.isError ? (<section className="ai-prompt-dashboard__error-container">
        <div className="ai-prompt-dashboard__header">AI Prompt Dashboard</div>
        <div className="ai-prompt-dashboard__error-message">
          <p>Something went wrong. Please try again later.</p>
        </div>
      </section>) :
        ((!selectedCategories.length && !aiPromptDashboardData.length) || (selectedCategories.length === aiPromptDashboardCategories.length && !aiPromptDashboardData) ?
          <>
          <RenderWhen.If isTrue={state.showDescriptionModal}>
            <DescriptionModal showDescriptionModal={state.showDescriptionModal} setShowDescriptionModal={(value: boolean) => setSpecificState('showDescriptionModal', value)} isDisabled={state.isDisabled} setIsDisabled={(value: boolean) => setSpecificState('isDisabled', value)} sortAndUpdateAIPromptDashboardData={sortAndUpdateAIPromptDashboardData} aiPromptDashboardData={aiPromptDashboardData} planMeta={planMeta} doRefresh={doRefresh} editedRowData={editedRowData} isEdit={isEdit} setIsEdit={setIsEdit}/>
          </RenderWhen.If>
            <section className="ai-prompt-dashboard__no-result-container">
              <div className="ai-prompt-dashboard__header">AI Prompt Dashboard</div>
              <div className="ai-prompt-dashboard__no-result-message">
                <p>No results found. It seems there is no data available currently.</p>
                <p>please try adding new data.</p>
                <div className="ai-prompt-dashboard__no-result-btn">
                  <Button
                    variant={BUTTON_VARIANT.NORMAL} type={BUTTON_TYPES.PRIMARY}
                    onClick={() => setSpecificState('showDescriptionModal', !state.showDescriptionModal)}
                  >
                    Add Data
                  </Button>
                </div>
              </div>
            </section>
          </>
          :
          <section className="ai-prompt-dashboard">
            <div className="ai-prompt-dashboard__header">AI Prompt Dashboard</div>
            <div className="flex-between">
            <Dropdown
              displayKey="label"
              uniqueKey="value"
              isMulti={true}
              open={state.categoryDropdownOpen}
              value={selectedCategories}
              targetFullWidth
              options={aiPromptDashboardCategories}
              optionsRendererHeight={500}
              onSelect={handleSelect}
              onClose={() => {
                setSpecificState('categoryDropdownOpen', false);
              }}
              onClick={() =>
                setSpecificState('categoryDropdownOpen', !state.categoryDropdownOpen)
              }
            >
              <div className="ai-prompt-dashboard__filter">
                <div className="ai-prompt-dashboard__filter-label">Filter by Category:</div>
                <div className="ai-prompt-dashboard__filter-list">
                  {selectedCategories.slice(0, 2).map((category: { label: string, value: string }) => (
                    <div key={category.value} className="ai-prompt-dashboard__filter-item">
                      <div className="ai-prompt-dashboard__filter-item-label">{category.label}</div>
                      <Close width={10} height={10} className="ai-prompt-dashboard__filter-item-icon" onClick={(event) => {
                        event.stopPropagation();
                        handleRemove(category);
                      }} />
                    </div>
                  ))}
                  {(selectedCategories.length - 2) > 0 && (
                    <div className="ai-prompt-dashboard__filter-item ai-prompt-dashboard__filter-item-summary">
                      +{selectedCategories.length - 2} selected
                    </div>
                  )}
                </div>
                <ChevronDown width={10} height={10} className="ai-prompt-dashboard__filter-icon" />
              </div>
            </Dropdown>
            <button
            className="add-new_buton"
             onClick={() => {
              setSpecificState('showDescriptionModal', !state.showDescriptionModal);
            }}
            >
              Add New
            </button>
            </div>
            {(selectedCategories.length && !aiPromptDashboardData.length) ?
              <section className="ai-prompt-dashboard__no-result-container" style={{minHeight: "70vh", background: "white"}}>
                <div className="ai-prompt-dashboard__no-result-message">
                  <p>No data found for the applied filters.</p>
                  <p>Please check your filters or try adjusting them to find relevant data.</p>
                </div>
              </section> :
              <>
                <Table<Record<string, string | boolean>>
                  className="custom-table"
                  columns={columns}
                  data={aiPromptDashboardData}
                  pageSize={31}
                  hasRowSelect={false}
                  hasAllRowSelect={false}
                />
                <Pagination
                  dataType="aiPromptDashboard"
                  pageSize={30}
                  setPageSize={() => { }}
                  currentPageIndex={currentPage - 1}
                  totalPage={totalPages}
                  previousPage={() => setCurrentPage((prev) => prev - 1)}
                  nextPage={() => setCurrentPage((prev) => prev + 1)}
                  goToPage={(pageIndex: number) => setCurrentPage(pageIndex + 1)}
                  canNextPage={currentPage < totalPages}
                  canPreviousPage={currentPage > 1}
                />
                <Modal
                headerRenderer={() =>(<></>)}
                open={canDeletePrompt}
                onCancel={() => setCanDeletePrompt(false)}
                okText="Confirm"
                onOK={() => {
                if (selectedRowId) handleDelete(selectedRowId);
                setCanDeletePrompt(false);
               }}
               size={MODAL_SIZES.SMALL}
                   cancelText="Cancel"
  onClose={() => setCanDeletePrompt(false)}
  
>
  <p style={{ padding: "24px" }}>
    Are you sure you want to delete this? You will not be able to use this henceforth.
  </p>
</Modal>
              </>}
            <RenderWhen.If isTrue={state.showDescriptionModal}>
              <DescriptionModal showDescriptionModal={state.showDescriptionModal} setShowDescriptionModal={(value: boolean) => setSpecificState('showDescriptionModal', value)} isDisabled={state.isDisabled} setIsDisabled={(value: boolean) => setSpecificState('isDisabled', value)} sortAndUpdateAIPromptDashboardData={sortAndUpdateAIPromptDashboardData} aiPromptDashboardData={aiPromptDashboardData} planMeta={planMeta} doRefresh={doRefresh} editedRowData={editedRowData} isEdit={isEdit} setIsEdit={setIsEdit}/>
            </RenderWhen.If>
          </section>

        )
    ) : <div className="ai-prompt-dashboard__no-result-container">You do not have the necessary permissions to view this content.</div>
  )
}

export default AIPromptDashboard;

function DescriptionModal({ showDescriptionModal, setShowDescriptionModal, isDisabled, setIsDisabled, sortAndUpdateAIPromptDashboardData, aiPromptDashboardData, planMeta, doRefresh, editedRowData, isEdit, setIsEdit}: IDescriptionModalProps) {

  const getInputFields = () => {
    if(isEdit){
      return {
        name: editedRowData?.name as string ||  "",
        category: {label: transformedObject[(editedRowData?.category || "")  as keyof typeof transformedObject] || "", value: editedRowData?.category || ""},
        description: editedRowData?.hint || "",
        prompt: editedRowData?.description || "",
        blueprints: editedRowData?.blueprints
      }
    } else {
      return {
        name: "",
        category: null,
        description: "",
        prompt:  "",
      }
    }
  }

  const getCurrentPlanDetailsState = () => {
    if(!isEdit){
      return {
        EMAIL_TONE: "",
        OUTCOME: "",
        FRAMEWORK: "",
        TOUCH_POINTS: 1,
        DELAY: 1,
      }
    }else {
      const currentBlueprint = editedRowData?.blueprints?.[0]
      return {
        EMAIL_TONE: currentBlueprint?.tone?.[0]?.id,
        OUTCOME: currentBlueprint?.outcome,
        FRAMEWORK: currentBlueprint?.frameworks,
        TOUCH_POINTS: editedRowData?.touchpoints,
        DELAY: currentBlueprint?.scheduleConfig?.delay?.day,
        }
      }
  }

  const [inputFields, setInputFields] = useState<{
    name: string;
    category: { label: string; value: string } | null;
    description: string;
    prompt: string;
    blueprints?: BlueprintsConfig[];
  }>(getInputFields());


  
  const [showCreatePlanModal, setCreatePlanModal] = useState(false);
  const [currPlanDetails, setCurrPlanDetails] = useState<CurrPlanDetailsType>(getCurrentPlanDetailsState());
  const isPlan = (showCreatePlanModal || inputFields?.category?.value === 'PLAN')


  const doPreSaveValidations = () => {
    const isMissingFields = !inputFields.category || !inputFields.description || !inputFields.name;
    const isInvalidPlan = isPlan && (!currPlanDetails?.OUTCOME || !currPlanDetails.FRAMEWORK || !inputFields.name);

    if ((!isPlan && isMissingFields) || isInvalidPlan) {
      showMessageBar({
        type: MESSAGE_BAR_TYPES.WARNING,
        heading: 'All fields are required.',
      });
      return false;
    }
    return true
  }

  const getPlanObj = () => {
    let planObj = {}
    if (isPlan) {
      const selectedTone = planMeta?.EMAIL_TONE?.find(
        (obj) => obj.id === currPlanDetails?.EMAIL_TONE
      );
      const tone = selectedTone
        ? [
            {
              isSelected: true,
              id: currPlanDetails?.EMAIL_TONE,
              name: selectedTone?.name,
              instruction: selectedTone?.description,
            },
          ]
        : undefined;

      planObj = {
        blueprints: [
          {
            frameworks: currPlanDetails?.FRAMEWORK,
            outcome: currPlanDetails?.OUTCOME,
            strategy: "SEQUENCE",
            scheduleConfig: {
              delay: {
                day: currPlanDetails?.DELAY || 1,
                mins: 0,
                hours: 0,
              },
              timeWindow: {
                min: 8,
                max: 20,
              },
              allowedDays: ["MON", "TUE", "WED", "THU", "FRI"],
            },
            tone,
          },
        ],
        touchpoints: currPlanDetails?.TOUCH_POINTS || 1,
      };
    }

    return planObj
  }

  const handleAddAIPromptDashboardData = async () => {

    if(!doPreSaveValidations()) return

    const planObj = getPlanObj()
    
    setIsDisabled(true);
    await axios.post('/api/agents/agentPromptMeta', {
      name: inputFields.name,
      category: inputFields?.category?.value,
      hint: inputFields.description,
      description: inputFields.prompt,
      ...planObj
    }).then((response) => {
        const { _id, id, name, category, hint, description } = response.data.data;
        let updatedDashboardData = aiPromptDashboardData.slice(0, -1);
        if (aiPromptDashboardData.length > 30) {
          updatedDashboardData = updatedDashboardData.slice(0, -1);
        }
        doRefresh()
        sortAndUpdateAIPromptDashboardData([{ _id, id, name, category, hint, description }, ...updatedDashboardData]);
        showMessageBar({
          type: MESSAGE_BAR_TYPES.SUCCESS,
          heading: `${inputFields?.category?.label} added successfully!`,
        });
    })
      .catch(handleErrors)
      .finally(() => {
        setInputFields({
          name: '',
          category: null,
          description: '',
          prompt: ''
        });
        setIsDisabled(false);
        setShowDescriptionModal(false);
      });
  }

  const handleSaveEditedData = async () => {
    if (!doPreSaveValidations()) return;

    const planObj = getPlanObj()

    await axios
      .put(`/api/agents/agentPromptMeta`, {
        _id: editedRowData._id,
        description: inputFields.prompt,
        category: inputFields.category,
        name: inputFields.name,
        hint: inputFields.description,
        ...planObj
      })
      .then(() => {
        doRefresh();
        setShowDescriptionModal(false);
        showMessageBar({
          type: MESSAGE_BAR_TYPES.SUCCESS,
          heading: "Data Updated Successfully",
        });
      })
      .catch(handleErrors);
  };

  const handleInputFieldChange = (field: keyof typeof inputFields, value: string | null) => {
    setInputFields((prev) => ({
      ...prev,
      [field]: value
    }));
  }

  return (
    <Modal
      title="Description"
      size={MODAL_SIZES.LARGE}
      open={showDescriptionModal}
      width="80%"
      defaultFixedHeight="700px"
      footerRenderer={() => (
        <div
          className="description-modal_actions actions-container"
          style={{ padding: "16px 24px" }}
        >
          <Button
            className="description-modal__button-cancel"
            onClick={() => {
              setInputFields({
                name: "",
                category: null,
                description: "",
                prompt: ""
              })
              setShowDescriptionModal(false);
              isEdit && setIsEdit(false)
            }}
            disabled={isDisabled}
          >
            Cancel
          </Button>
          <Button
            className="description-modal__button-save"
            onClick={isEdit ? handleSaveEditedData : handleAddAIPromptDashboardData}
            disabled={isDisabled}
          >
            Save
          </Button>
        </div>
      )}
    >
      <div className="description-modal__form-container">
        <div className="description-modal__form-input-container">
          <TextInput
            fullWidth
            label="Name"
            placeholder={"Enter the Name"}
            value={inputFields.name}
            onChange={(value) => handleInputFieldChange("name", value)}
          />
          <SingleSelect
            fullWidth
            
            label="Category"
            placeholder={"Enter the Category"}
            value={inputFields.category}
            disabled={!inputFields.name.trim().length || isEdit}
            onSelect={(category) => {
              handleInputFieldChange("category", category);
               setCreatePlanModal(category.label === "Plan");
            }}
            options={aiPromptDashboardCategories}
            displayKey="label"
            uniqueKey="value"
          />
          <div className="description-modal__description-textarea">
            <RenderWhen.If isTrue={isPlan}>
              <CreatePlanModal planMeta={planMeta} planDetailsState= {[currPlanDetails, setCurrPlanDetails]} oldBlueprintsDoc={editedRowData?.blueprints?.[0]}/>
            </RenderWhen.If>
            <RenderWhen.If isTrue={!isPlan}>
              <TextAreaInput
                label="Description (Enter the prompt that is showin in the UI in App)"
                fullWidth
                placeholder="Enter the Description"
                value={inputFields.description}
                minHeight="352px"
                onChange={(value) =>
                  handleInputFieldChange("description", value)
                }
              />
            </RenderWhen.If>
          </div>
        </div>
        <div className ={classNames({
            "description-modal__prompt-textarea": true,
            "description-modal__prompt-textarea_blur": isPlan,
        })}>
          <div className="description-modal__prompt-textarea-label">Prompt</div>
          <TextAreaInput
            disabled={isPlan}
            fullWidth
            placeholder="Enter the prompt"
            value={inputFields.prompt}
            minHeight="510px"
            onChange={(value) => handleInputFieldChange("prompt", value)}
          />
        </div>
      </div>
    </Modal>
  );
}