import {
  NumberInput,
  Radio,
  RenderWhen,
  Tab,
  Tabs,
  TextAreaInput,
} from "@klenty/klenty-ui";
import React, { useState } from "react";

import styles from "./createPlanModal.module.css";
import {
  BlueprintsConfig,
  CurrPlanDetailsType,
  PlanMetaCategoryType,
  PlanMetaItem,
} from "./types";

type CreatePlanModalProps = {
  planMeta: Record<PlanMetaCategoryType, PlanMetaItem[]> | undefined;
  planDetailsState: [
    CurrPlanDetailsType,
    React.Dispatch<React.SetStateAction<CurrPlanDetailsType>>
  ];
  oldBlueprintsDoc: BlueprintsConfig;
};

enum CREATE_PLAN_FIELDS {
  FRAMEWORK = "Framework",
  OUTCOME = "Outcome/CTA",
  TONE = "Tone",
  OUTREACH = "Outreach",
}

const CreatePlanModal = ({
  planMeta,
  planDetailsState,
}: CreatePlanModalProps) => {
  const [selectedField, setSelectedField] = useState<CREATE_PLAN_FIELDS>(
    CREATE_PLAN_FIELDS.FRAMEWORK
  );

  const [currPlanDetails, setCurrPlanDetails] = planDetailsState;

  return (
    <div>
      <Tabs
        activeTabId={selectedField}
        onChange={(e) => setSelectedField(e as CREATE_PLAN_FIELDS)}
      >
        {Object.values(CREATE_PLAN_FIELDS).map((field, index) => (
          <Tab name={field} key={selectedField + index} tabId={field}>
            <div className={styles.fieldsInputContainer}>
              <RenderWhen.If
                isTrue={CREATE_PLAN_FIELDS.FRAMEWORK === selectedField}
              >
                <TextAreaInput
                  placeholder="Enter the Framework Prompt"
                  value={currPlanDetails.FRAMEWORK}
                  onChange={(val) =>
                    setCurrPlanDetails((prev) => ({
                      ...prev,
                      FRAMEWORK: val,
                    }))
                  }
                  fullWidth
                  minHeight="320px"
                />
              </RenderWhen.If>
              <RenderWhen.If
                isTrue={CREATE_PLAN_FIELDS.OUTCOME === selectedField}
              >
                <div className={styles.outComeContainer}>
                  <div className={styles.title_1}>OutCome</div>
                  <div>
                    {planMeta?.["OUTCOME"]?.map((obj, i: number) => (
                      <Radio
                        checked={currPlanDetails?.OUTCOME === obj.id}
                        key={obj.name + i}
                        onChange={() =>
                          setCurrPlanDetails((prev) => ({
                            ...prev,
                            OUTCOME: obj.id,
                          }))
                        }
                      >
                        {obj.name}
                      </Radio>
                    ))}
                  </div>
                </div>
              </RenderWhen.If>
              <RenderWhen.If isTrue={CREATE_PLAN_FIELDS.TONE === selectedField}>
                <div className={styles.outComeContainer}>
                  <div className={styles.title_1}>Email Tone</div>
                  <div>
                    {planMeta?.["EMAIL_TONE"]?.map((obj, i: number) => (
                      <Radio
                        checked={currPlanDetails?.EMAIL_TONE === obj.id}
                        key={obj.name + i}
                        onChange={() =>
                          setCurrPlanDetails((prev) => ({
                            ...prev,
                            EMAIL_TONE: obj.id,
                          }))
                        }
                      >
                        {obj.name}
                      </Radio>
                    ))}
                  </div>
                </div>
              </RenderWhen.If>
              <RenderWhen.If
                isTrue={CREATE_PLAN_FIELDS.OUTREACH === selectedField}
              >
                <div>
                  <div className={styles.toneContainer}></div>
                  <div>
                    <NumberInput
                      start={1}
                      label="Number of touchpoints"
                      fullWidth
                      onChange={(val) =>
                        setCurrPlanDetails((prev) => ({
                          ...prev,
                          TOUCH_POINTS: val,
                        }))
                      }
                      value={currPlanDetails.TOUCH_POINTS}
                    />
                    <NumberInput
                      start={1}
                      label="Delay Between Steps"
                      fullWidth
                      onChange={(val) =>
                        setCurrPlanDetails((prev) => ({
                          ...prev,
                          DELAY: val,
                        }))
                      }
                      value={currPlanDetails.DELAY}
                    />
                  </div>
                </div>
              </RenderWhen.If>
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default CreatePlanModal;
