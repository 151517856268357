export enum PlanMetaCategoryType {
  EMAIL_TONE = "EMAIL_TONE",
  OUTCOME = "OUTCOME",
  FRAMEWORK = "FRAMEWORK",
}

export type PlanMetaItem = {
  id: string;
  name: string;
  description: string;
};

export type PlanMetaCategory = {
  _id: PlanMetaCategoryType;
  items: PlanMetaItem[];
};

export type PlanMetaApiResponse = PlanMetaCategory[];

export type CurrPlanDetailsType = {
  TOUCH_POINTS: number;
  DELAY: number;
} & {
  [K in keyof typeof PlanMetaCategoryType]: string;
};

export const transformedObject = {
  FRAMEWORK: "Framework",
  EMAIL_TONE: "Email Tone",
  OUTCOME: "Outcome",
  KEY_MESSAGE: "Key Message",
  PLAN: "Plan",
};

export type ScheduleConfig = {
  delay: {
    day: number;
    mins: number;
    hours: number;
  };
  timeWindow: {
    min: number;
    max: number;
  };
  allowedDays: Array<"MON" | "TUE" | "WED" | "THU" | "FRI">;
};

export type Tone = {
  isSelected: boolean;
  id: string;
  name: string;
  instruction: string;
};

export type BlueprintsConfig = {
  frameworks: string;
  outcome: string;
  strategy: string;
  scheduleConfig: ScheduleConfig;
  tone: Tone[];
};
